import * as React from "react"
import Layout from "../components/layout"
import '../style.css'
import Star from "../components/star";
import { Link } from "gatsby";
import { FaArrowAltCircleRight} from "@react-icons/all-files/fa/FaArrowAltCircleRight";
import { GiMusicalNotes } from "@react-icons/all-files/gi/GiMusicalNotes";
import { FaVideo } from "@react-icons/all-files/fa/FaVideo";
import { FaFilePowerpoint } from "@react-icons/all-files/fa/FaFilePowerpoint";

const Media = () => {
    //Landing page for media
    const media=[
        {
          video:true,
          title:'The Swift Video',
          page:'swiftVideo',
          description:'In 1967, intense flashes of gamma rays were first observed from space. These brilliant bursts of energy burned brighter than all other gamma-ray sources in the sky.',
        },
        {
          video:true,
          title:"Black Hole's Magnetic Reversal",
          page:'monsterBlackhole',
          description:'A rare and enigmatic outburst from a galaxy 236 million light-years away may have been sparked by a magnetic reversal, a spontaneous flip of the magnetic field surrounding its central black hole.',
        },
        {
            video:true,
            title:'Mini-Jet near Black Hole',
            page:'miniJet',
            description:' Our Milky Way’s central black hole has a leak! This supermassive black hole, over 4 million times more massive than our Sun, looks like it still has the remnants of a blowtorch-like jet dating back several thousand years. '
        },
        {
          video:true,
          title:'Black Hole Video',
          page:'blackhole',
          description:'A short 26 second video, which shows an animation of a distant black hole complete with jets and an accretion disk. (No Audio)',
        },
        {
          video:false,
          title:'The Swift Song',
          page:'swiftSong',
          description:"The Swift Song is the theme song of the mission; an upbeat, catchy pop tune which teaches listeners about the math and science behind the elusive gamma ray bursts and the Swift satellite. It's a roller coaster gamma ray ride!",
        },
    ]

    return (
    <Layout>
        <div>
            <h1>SWIFT OBSERVATORY MULTIMEDIA</h1>
            <Star/>
            <div className='media-items-container'>
                {media.map( item => 
                <div className='item-container' key={item.title}>
                    
                    {item.video ? 
                        <FaVideo style={{width:'35px', height:'35px', color:'#facb65', margin:'5px'}}/> 
                        : 
                        <GiMusicalNotes style={{width:'35px', height:'35px', color:'#facb65', margin:'5px'}}/>
                    }

                    <h4>
                        <Link to={`/mediaSubpages/${item.page}`}>
                            {item.title}
                        </Link>
                    </h4>

                    <p>{item.description}</p>

                    <p className="media-item-buttons">
                        <Link to={`/mediaSubpages/${item.page}`} >
                            Check it out! <FaArrowAltCircleRight/>
                        </Link>
                    </p>
                </div>
                 )}

                {/* Powerpoint */}
                <div className="item-container">
                    <FaFilePowerpoint style={{width:'35px', height:'35px', color:'#facb65', margin:'5px'}}/>
                    <h4>
                        <Link to='/mediaSubpages/powerpoints'>
                            Powerpoints
                        </Link>
                    </h4>
                    <p>Read the very informative presentations put together by various experts from the begining of the Swift mission</p>
                    <p className="media-item-buttons">
                        <Link to='/mediaSubpages/powerpoints'>
                            Check it out! <FaArrowAltCircleRight/>
                        </Link>
                    </p>
                </div>  

            </div>
        </div>
    </Layout>
  )
}

export default Media
